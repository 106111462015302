import Image from 'next/image';
import imgItem from '@/assets/img-burger.png';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import {
  decrementQuantity,
  incrementQuantity,
  removeFromCart,
} from '@/redux/slices/cart.slice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { AddToCart, UpdateCartItem } from '@/services/cart.api';
import { MdDeleteOutline } from 'react-icons/md';
import AppStorage from '@/utils/helpers';

const CartItem = (cartItem: any) => {
  const { cart } = cartItem;
  const dispatch = useDispatch();
  const outlet_id = AppStorage.get('outlet_id') as string;

  const { user } = useAppSelector((state: any) => state.auth);
  const { cart: cartList } = useAppSelector((state: any) => state.cart);

  const update = async (action: string) => {
    const cartDataToBeUpdated = cartList.map((item: any) => {
      let newCart = [];

      if (item.id === cart.id) {
        let newData = {
          menuId: cart.id,
          quantity: action === 'add' ? cart.quantity + 1 : cart.quantity - 1,
        };
        newCart.push(newData);
      } else {
        let newData = {
          menuId: item.id,
          quantity: item.quantity,
        };
        newCart.push(newData);
      }

      return newCart;
    });

    // Flatten the array of arrays into a single array of objects
    const updatedMenu = cartDataToBeUpdated.reduce(
      (acc: any, curr: any) => acc.concat(curr),
      []
    );

    if (action === 'add') {
      if (!user) {
        dispatch(incrementQuantity(cart.id));
      } else {
        try {
          const data = {
            user_id: user._id,
            menuItems: [...updatedMenu],
          };

          const res = await AddToCart(data);
          dispatch(incrementQuantity(cart.id));
        } catch (error) {}
      }
    } else if (action === 'remove') {
      const newMenu = updatedMenu.filter(
        (item: any) => item.menuId !== cart.id
      );

      if (!user) {
        dispatch(removeFromCart(cart.id));
      } else {
        try {
          const data = {
            user_id: user._id,
            menuItems: [...newMenu],
          };

          const res = await AddToCart(data);
          dispatch(removeFromCart(cart.id));
        } catch (error) {}
      }
    } else {
      if (cart.quantity > 1) {
        if (!user) {
          dispatch(decrementQuantity(cart.id));
        } else {
          try {
            const data = {
              user_id: user._id,
              menuItems: [...updatedMenu],
            };

            const res = await AddToCart(data);
            dispatch(decrementQuantity(cart.id));
          } catch (error) {}
        }
      }
    }
  };

  return (
    <>
      <section className="flex flex-col bg-white px-4">
        <ul className="border-b border-gray-200 flex relative items-center w-full gap-4 p-4">
          <li className="w-[30%] bg-gray-600 block shrink-0 grow-0">
            <img
              src={cart?.cartData?.image_url}
              alt="item image"
              className="w-full object-cover"
            />
          </li>
          <li className="flex flex-col grow gap-3">
            <p className="text-base font-bold">{cart?.cartData?.name}</p>
            <div className="flex flex-col">
              <small className="font-normal">{cart?.cartData?.category}</small>
            </div>
          </li>
          <div onClick={() => update('remove')}>
            <MdDeleteOutline color="red" className="text-2xl md:text-3xl" />
          </div>
        </ul>
        <ul className="flex justify-between py-4 px-4 items-center">
          <li className="font-semibold">
            N
            {outlet_id && cart?.cartData?.outlet_menu.price
              ? cart?.cartData?.outlet_menu?.price.toLocaleString()
              : cart?.cartData?.price.toLocaleString()}
          </li>
          <li className="p-1 flex items-center gap-4">
            <p className="text-primary-60 font-semibold">change</p>
            <div className="flex flex-nowrap text-sm rounded-3xl overflow-hidden bg-gray-100">
              <button onClick={() => update('reduce')} className="px-4 py-3">
                <MinusIcon className="w-4 h-4" />
              </button>
              <div className="px-3 py-3">{cart?.quantity}</div>
              <button onClick={() => update('add')} className=" px-4 py-3">
                <PlusIcon className="w-4 h-4" />
              </button>
            </div>
          </li>
        </ul>
      </section>
    </>
  );
};

export default CartItem;
