import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  location: null,
};

const userLocationSlice = createSlice({
  name: 'userLocation',
  initialState,
  reducers: {
    updateUserLocation(state, action) {
      state.location = action.payload;
    },
    hydrateUserLocation(state, action) {
      const location = action.payload;
      if (location) {
        state.location = location;
      }
    },
  },
});

const { actions, reducer: UserLocationReducer } = userLocationSlice;

export const { updateUserLocation, hydrateUserLocation } = actions;
export default UserLocationReducer;
