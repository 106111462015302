import React, { Fragment, useEffect, useState } from 'react';
import NoOutletModal from './NoOutletModal';
import GoogleMap from './GoogleMap';
import { ClipLoader } from 'react-spinners';
import {
  GetAddress,
  GetOutletsAroundMe,
  GetUserLatAndLong,
} from '@/services/order.api';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
// import { BsSend } from 'react-icons/bs';
import { GetOutletMenusForCustomer } from '@/services/menu.api';
import { addToMenu } from '@/redux/slices/category.slice';
import { toast } from 'react-toastify';
import AppStorage from '@/utils/helpers';

type Props = {
  openModal: boolean;
  closeModal: () => void;
};

function AddLocationModal({ openModal, closeModal }: Props) {
  const [location, setLocation] = useState({ lat: 9.082, lng: 8.6753 });
  const [loading, setLoading] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [value, setValue] = useState('');
  const [selectedPlaceId, setSelectedPlaceId] = useState('');
  const [outletsLoading, setOutletsLoading] = useState(false);
  const [addressData, setAddressData] = useState<any>();
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const { user } = useSelector((state: any) => state.auth);
  const [predictionData, setPredictionData] = useState<any[]>([]);
  const [predictionLoading, setPredictionLoading] = useState(false);
  const [showResults, setShowResults] = useState(0);
  const [showNoOutletModal, setShowNoOutletModal] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const dispatch = useDispatch();
  const fetchPlaceDetails = async (placeId: string) => {
    try {
      setLoading(true);
      const response: any = await GetUserLatAndLong(placeId);

      const outlets = await GetOutletsAroundMe(
        response?.result?.geometry?.location?.lat,
        response?.result?.geometry?.location?.lng
      );

      setShowResults(2);
      setOutlets(outlets);

      const addressComponent = response.result.address_components;
      const addressComponentLength = response.result.address_components.length;
      const address = {
        address: addressComponent[0].long_name,
        street: addressComponent[1].long_name,
        city:
          addressComponentLength > 2
            ? addressComponent[2].long_name
            : addressComponent[1].long_name,
        lat: response?.result?.geometry?.location?.lat,
        long: response?.result?.geometry?.location?.lng,
        state:
          addressComponentLength > 3
            ? addressComponent[3].long_name
            : addressComponent[2].long_name,
        // name:
      };

      setAddressData(address);
      setLocation({
        lat: response?.result?.geometry?.location?.lat,
        lng: response?.result?.geometry?.location?.lng,
      });

      setIsMapLoaded(true);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedPlaceId) {
      fetchPlaceDetails(selectedPlaceId);
    }
  }, [selectedPlaceId]);

  const getAddress = async () => {
    try {
      setLoadingSearch(true);
      setPredictionLoading(true);
      const res = await GetAddress(value);

      const mappedData = res.predictions.map((prediction: any) => {
        const { description, place_id } = prediction;

        return { description, place_id };
      });
      setShowResults(1);
      setPredictionData(mappedData);
    } catch (err) {
      setPredictionLoading(false);
    } finally {
      setLoadingSearch(false);
      setPredictionLoading(false);
    }
  };

  useEffect(() => {
    if (value.length >= 3 && !selectedPlaceId) {
      getAddress();
    }
  }, [value, selectedPlaceId]);

  const [mapCenter, setMapCenter] = useState<any>({ lat: 0, lng: 0 });
  const [mapMarkers, setMapMarkers] = useState([
    { lat: 9.082, lng: 8.6753, text: 'marker' },
  ]);

  const [mapZoom, setMapZoom] = useState(7);

  useEffect(() => {
    setMapCenter({ lat: location.lat, lng: location.lng });

    const newMapMarkers = [
      {
        lat: location.lat,
        lng: location.lng,
        text: 'marker',
      },
    ];
    if (isMapLoaded) setMapZoom(15);
    setMapMarkers(newMapMarkers);
  }, [location.lat, location.lng, isMapLoaded]);

  useEffect(() => {
    setMapZoom(7);
  }, []);

  const [outlets, setOutlets] = useState<any>([]);

  const setOutletAddress = async (item: any) => {
    const address = {
      address: item.address,
      street: item.address,
      city: item.state,
      name: item.name,
      lat: item.location.coordinates[1],
      long: item.location.coordinates[0],
      state: item.state,
      id: item._id,
    };
    setAddressData(address);
    setLocation({
      lat: item.location.coordinates[1],
      lng: item.location.coordinates[0],
    });
    setIsMapLoaded(true);
  };

  const selectMenu = async () => {
    AppStorage.save('outlet_id', addressData.id);
    AppStorage.save('outlet_name', addressData.name);
    AppStorage.save('outlet_address', addressData.address);

    const payload = {
      ...addressData,
      userid: user ? user._id : '',
    };

    AppStorage.save('location', JSON.stringify(payload));
    AppStorage.save('orderType', 'delivery');
    try {
      setLoadingMenu(true);
      const res = await GetOutletMenusForCustomer(addressData.id);
      dispatch(addToMenu(res));
    } catch (err) {
      toast.error('Something went wrong fetching outlet menus');
    } finally {
      setLoadingMenu(false);
    }
    closeModal();
  };

  return (
    <div>
      <Transition appear show={openModal} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0  overflow-y-auto">
            <div className="flex min-h-full  items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="flex flex-col w-full  transform overflow-hidden rounded-lg bg-white p-0 text-left align-middle shadow-xl transition-all">
                  {/* <div className="p-10 relative"> */}
                  <button
                    type="button"
                    onClick={() => {
                      closeModal();
                    }}
                    className="p-3 mb-2 absolute top-2 right-5 rounded-full w-fit  bg-white hover:bg-gray-100 border border-gray-200"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>

                  <div
                    onClick={() => {
                      closeModal();
                    }}
                    className="flex justify-between  relative items-center"
                  >
                    <h2 className="text-2xl p-5">Choose Outlet close to you</h2>
                  </div>

                  <div
                    className={`flex  flex-col md:flex-row justify-between items-start ${
                      predictionData.length > 0 || outlets.length > 0
                        ? 'h-[1150px]'
                        : 'h-[720px]'
                    } md:h-screen px-2 mt-5  md:px-10 pb-20`}
                  >
                    <div
                      className={`w-full flex-[.3]  ${
                        predictionData.length > 0 || showResults === 2
                          ? 'h-[1000px]'
                          : 'h-10'
                      } md:h-fit`}
                    >
                      <div
                        className={` relative p-1 overflow-hidden  md:p-2 flex flex-col w-full`}
                      >
                        <input
                          value={value}
                          onChange={(evt: any) => {
                            setValue(evt.target.value);
                            setSelectedPlaceId('');
                          }}
                          className="w-full outline-0 px-4 md:py-4 py-3 border border-[#ccc] rounded-full"
                          placeholder="Search for outlet within your locality"
                        />
                        {loadingSearch && (
                          <div className="w-10 h-10 absolute top-4 right-5">
                            <ClipLoader color="#ca1611" />
                          </div>
                        )}

                        {showResults === 1 && (
                          <div className="w-full ">
                            {predictionData.map((item: any, index: any) => {
                              return (
                                <div
                                  onClick={() => {
                                    setValue(item.description);
                                    setSelectedPlaceId(item.place_id);
                                    setPredictionData([]);
                                  }}
                                  key={index}
                                  className="border-[#ccc] my-2 border items-center flex justify-between p-2 md:p-4 rounded-xl w-[98%] "
                                >
                                  <p className="font-poppinsRegular py-2 flex-[.75]  text-start cursor-pointer">
                                    {item.description}
                                  </p>
                                  <p className="text-[10px] text-red-500 cursor-pointer">
                                    GET NEARBY OUTLETS
                                  </p>
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {loading && (
                          <div className="w-full h-40 flex justify-center items-center">
                            {' '}
                            <ClipLoader color="#ca1611" />
                          </div>
                        )}
                        {showResults === 2 && (
                          <div className="w-full  overflow-scroll">
                            {outlets.length ? (
                              outlets.map((item: any, index: number) => {
                                return (
                                  <div
                                    onClick={() => {
                                      setOutletAddress(item);
                                      setShowResults(3);
                                      setOutlets([]);
                                    }}
                                    key={index}
                                    className="border-[#ccc] my-2 border items-center flex justify-between p-2 md:p-4 rounded-xl w-[98%] "
                                  >
                                    <div className="flex-[.9]">
                                      <p className="font-poppinsRegular py-2 flex-[.75]  text-start cursor-pointer">
                                        {item.name}
                                      </p>
                                      <p className="font-poppinsRegular py-2 flex-[.75] text-sm  text-start cursor-pointer">
                                        {item.address}
                                      </p>
                                      <p className="text-xs text-red-500 cursor-pointer">
                                        SHOW ON MAP
                                      </p>
                                    </div>
                                    <div className="flex-[.1]">
                                      {outletsLoading &&
                                      selectedPlaceId === item.place_id ? (
                                        <ClipLoader color="#ca1611" />
                                      ) : (
                                        <div>
                                          {/* <BsSend className="text-2xl flex-[.25]" /> */}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <div className="flex p-5 flex-col justify-center items-center">
                                {/* <Image
                                  src={NoOutlet}
                                  className="my-10"
                                  alt={'no otlet image'}
                                /> */}
                                <p className="text-center mb-5">
                                  We presently do not have an outlet at your
                                  location
                                </p>
                              </div>
                            )}
                          </div>
                        )}
                        {showResults === 3 && (
                          <div
                            onClick={selectMenu}
                            className="bg-[#179F83] p-4 w-full cursor-pointer rounded-full uppercase outline-0 my-6 text-md text-center text-white block"
                          >
                            {loadingMenu
                              ? 'Loading...'
                              : ' Proceed to Select Menu'}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="flex md:flex-[.7] border rounded-lg w-full md:w-full md:h-full h-[450px] bg-[#ccc]">
                      {!predictionLoading && !loading && (
                        <GoogleMap
                          outletName={
                            addressData?.name ? addressData?.name : ''
                          }
                          outletAddress={
                            addressData?.address ? addressData?.address : ''
                          }
                          center={mapCenter}
                          zoom={mapZoom}
                          markers={mapMarkers}
                        />
                      )}
                    </div>
                  </div>

                  <NoOutletModal
                    open={showNoOutletModal}
                    closeModal={() => {
                      setShowNoOutletModal(false);
                    }}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

export default AddLocationModal;
