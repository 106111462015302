'use client';

import { Fragment, useState, useCallback, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import LoginContent from './LoginContent';
import SignupContent from './SignupContent';
import { usePathname } from 'next/navigation';
import NoOutlet from '@/assets/no-outlet.png';
import Image from 'next/image';
import AppStorage from '@/utils/helpers';

type OpenType = {
  open: boolean;
  closeModal: () => void;
};

const NoOutletModal = ({ open, closeModal }: OpenType) => {
  const pathname = usePathname();

  useEffect(() => {
    AppStorage.save('pathName', pathname);
  }, []);

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-150"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="flex flex-col w-full max-w-2xl transform overflow-hidden rounded-lg bg-white p-0 text-left align-middle shadow-xl transition-all">
                <div className="grow w-full">
                  <button
                    type="button"
                    onClick={closeModal}
                    className="p-3 rounded-full w-fit mt-5 ml-5 bg-white hover:bg-gray-100 border border-gray-200"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>

                  <Dialog.Title
                    as="div"
                    className="text-lg font-medium justify-center leading-6 text-center w-full text-gray-900 flex px-6 items-center"
                  >
                    We are coming to your location!
                  </Dialog.Title>
                  <div className="flex p-5 flex-col justify-center items-center">
                    <Image
                      src={NoOutlet}
                      className="my-10"
                      alt={'no otlet image'}
                    />
                    <p className="text-center mb-5">
                      We presently do not have an outlet at your location
                    </p>
                    <button
                      onClick={closeModal}
                      className={`block text-center font-semibold p-4 w-full rounded-full ${'bg-green-400'} text-white`}
                    >
                      Okay
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default NoOutletModal;
