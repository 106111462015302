import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import MapMarker from './MapMarker';
import { ClipLoader } from 'react-spinners';

// Define a type for the Marker data
type Marker = {
  lat: number;
  lng: number;
  text: string;
};

type MapProps = {
  center: {
    lat: number;
    lng: number;
  };
  zoom: number;
  markers: Marker[];
  outletName?: string;
  outletAddress?: string;
};

const GoogleMap = ({
  center,
  zoom,
  markers,
  outletAddress,
  outletName,
}: MapProps) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {loading ? (
        <div className="w-24 h-24 ">
          <ClipLoader color="#ca1611" />
        </div>
      ) : (
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY as string,
          }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          {markers.map((marker, index) => (
            <MapMarker
              key={index}
              text={marker.text}
              name={outletName}
              address={outletAddress}
            />
          ))}
        </GoogleMapReact>
      )}
    </div>
  );
};

export default GoogleMap;
