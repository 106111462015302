'use client';

import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import CartItem from '../CartItem';
import CheckoutTab from '@/components/global/checkout-tab';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRelatedCartItems,
  updateCartModalState,
} from '@/redux/slices/cart.slice';
import { GetCartItems } from '@/services/cart.api';
import RelatedCartItem from '../RelatedCartItem';
import AppStorage, {
  getRelatedProducts,
  mapItemsByCategory,
} from '@/utils/helpers';
import { GetOutletMenusForCustomer } from '@/services/menu.api';

type CartModalProps = {
  open: boolean;
  closeModal: () => void;
};

export default function ModalCart({ open, closeModal }: CartModalProps) {
  const { cart, relatedCartItems } = useSelector((state: any) => state.cart);
  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const chosenOutlet = JSON.parse(AppStorage.get('outlet') || '{}');

  async function fetchMenuContent(id: string) {
    const res = await GetOutletMenusForCustomer(id);
    const categorized = mapItemsByCategory(res);
    if (cart.length) {
      let excludeIds: string[] = [];
      const categories: string[] = [];

      cart.forEach((product: any) => {
        excludeIds.push(product.id);
        if (
          product.cartData &&
          Array.isArray(product.cartData.relatedCategories)
        ) {
          categories.push(...product.cartData.relatedCategories);
        }
      });

      const allRelatedProducts = getRelatedProducts(
        categorized.all,
        categories,
        excludeIds
      ).filter((item: any) => item.is_available);

      // Generate a random index with 3 decimal places
      const randomIndex = Math.floor(Math.random() * 1000) / 1000;

      // Calculate the starting index for slicing
      const startIndex = Math.floor(
        randomIndex * (allRelatedProducts.length - 2)
      );

      // Slice 3 items starting from the random index
      const related = allRelatedProducts.slice(startIndex, startIndex + 3);
      dispatch(setRelatedCartItems(related));
    }
  }
  useEffect(() => {
    const totalPrice: number = cart?.reduce(
      (total: number, item: any) =>
        total + item?.cartData?.price * item.quantity,
      0
    );
    setCartTotalPrice(totalPrice);
  }, [cart]);

  const dispatch = useDispatch();

  const getCartItems = async () => {
    try {
      await GetCartItems();
    } catch (err) {}
  };

  useEffect(() => {
    getCartItems();
  }, []);
  useEffect(() => {
    if (chosenOutlet._id) {
      fetchMenuContent(chosenOutlet._id);
    }
  }, [cart]);

  useEffect(() => {
    if (cart.length === 0) {
      AppStorage.remove('coupon_type');
      AppStorage.remove('coupon_rate');
      AppStorage.remove('coupon_code');
      AppStorage.remove('couponPrice');
    }
  }, [cart]);

  const setPrice = (price: number) => {
    setCartTotalPrice(price);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-20"
          onClose={() => {
            closeModal();
            dispatch(updateCartModalState(false));
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-[-100%]"
                enterTo="opacity-100 translate-y-0"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-[-100%]"
              >
                <Dialog.Panel className="w-full lg:max-w-[50%] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button
                    type="button"
                    onClick={() => {
                      closeModal();
                      dispatch(updateCartModalState(false));
                    }}
                    className="absolute top-4 right-4 p-2 rounded-full bg-white hover:bg-gray-100 border border-gray-200"
                  >
                    <XMarkIcon className="w-6 h-6" />
                  </button>

                  {cart?.length > 0 ? (
                    <>
                      <Dialog.Title className="text-2xl font-poppinsBold tracking-tight leading-6 text-gray-900 mb-4">
                        {cart?.length} item{cart?.length > 1 && 's'} - ₦
                        {cartTotalPrice.toLocaleString()}
                      </Dialog.Title>

                      <div className="mt-4 max-h-[60vh] overflow-y-auto">
                        {cart.map((item: any, index: number) => (
                          <div className="my-1" key={index}>
                            <CartItem key={item.id} cart={item} />
                          </div>
                        ))}
                      </div>
                      {relatedCartItems.length > 0 && (
                        <>
                          <h3 className="text-center w-full">
                            You can also add...
                          </h3>
                          <br />
                          <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                            {relatedCartItems.map(
                              (cartItem: any, index: number) => (
                                <RelatedCartItem
                                  key={cartItem.id || index}
                                  cartItem={cartItem}
                                />
                              )
                            )}
                          </div>
                        </>
                      )}

                      <CheckoutTab
                        closeModal={() => {
                          closeModal();
                          dispatch(updateCartModalState(false));
                        }}
                        cartItem={cart}
                        price={cartTotalPrice}
                        setCartTotalPrice={setPrice}
                      />
                    </>
                  ) : (
                    <div className="text-center">
                      <h4 className="text-xl font-rakkas mb-4">
                        Your cart is empty
                      </h4>
                      <a href="/menu">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-primary-400 px-4 py-2 text-sm font-medium text-white hover:bg-primary-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                        >
                          Add Items
                        </button>
                      </a>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
