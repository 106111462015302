import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type CategoryState = {
  category: string[];
  menu: any[];
  outlet: null;
  reload: boolean;
  openAddLocationModal: boolean;
};

const initialState: CategoryState = {
  category: [],
  menu: [],
  outlet: null,
  reload: false,
  openAddLocationModal: false,
};

const categorySlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    addToCategory: (state, { payload }: PayloadAction<string[]>) => {
      state.category = payload;
    },
    addToMenu: (state, { payload }: PayloadAction<any[]>) => {
      state.menu = payload;
    },
    addToOutlet: (state, { payload }: PayloadAction<any>) => {
      state.outlet = payload;
    },
    reloadMenu: (state, { payload }: PayloadAction<boolean>) => {
      state.reload = payload;
    },
    setOpenAddLocationModal: (state, { payload }: PayloadAction<boolean>) => {
      state.openAddLocationModal = payload;
    },
  },
});

const { actions, reducer: CategoryReducer } = categorySlice;

export const {
  addToCategory,
  addToMenu,
  addToOutlet,
  reloadMenu,
  setOpenAddLocationModal,
} = actions;
export default CategoryReducer;
