import { truncateText } from '@/utils/helpers';
import React from 'react';
import { PiFlagPennantFill } from 'react-icons/pi';

type MarkerProps = {
  text: string;
  name?: string;
  address?: string;
};

const MapMarker: React.FC<MarkerProps> = ({ text, name, address }) => {
  return (
    <>
      {address && name ? (
        <div className="w-40 h-32 bg-gray-50 text-black rounded-lg p-3">
          <PiFlagPennantFill size={'25px'} color="red" />
          <div>
            <h1 className=" font-bold">{truncateText(name, 40)}</h1>
            <h1>{truncateText(address, 60)}</h1>
          </div>
        </div>
      ) : (
        <div
          style={{
            color: 'white',
            background: 'red',
            padding: '10px 15px',
            display: 'inline-flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
          }}
        >
          <PiFlagPennantFill size={'25px'} />
        </div>
      )}
    </>
  );
};

export default MapMarker;
