import Image from 'next/image';
import imgItem from '@/assets/img-burger.png';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import {
  addToCart,
  decrementQuantity,
  incrementQuantity,
} from '@/redux/slices/cart.slice';
import { useDispatch } from 'react-redux';

const RelatedCartItem = (cartItem: any) => {
  const { cartItem: cart } = cartItem;

  const dispatch = useDispatch();

  const { user } = useAppSelector((state) => state.auth);

  const payload = {
    id: cart._id,
    quantity: 1,
    isCombo: cart.isCombo,
    cartData: { ...cart },
  };

  const addItemsToCart = async () => {
    if (cart.is_available && !cart.is_combo) {
      if (!user) {
        dispatch(addToCart(payload));
      } else {
        dispatch(addToCart(payload));

        const data = {
          menuItem: cart._id,
          quantity: 1,
        };
      }
    }
  };

  return (
    // <>
    //   {!cart.isCombo ? (
    //     <section className="flex flex-col bg-white px-4">
    //       <ul className="border-b border-gray-200 flex relative items-center w-full gap-4 p-4">
    //         <li className="w-[30%] bg-gray-600 block shrink-0 grow-0">
    //           <img
    //             src={cart.image_url}
    //             alt="item image"
    //             className="w-full object-cover"
    //           />
    //         </li>
    //         <li className="flex flex-col grow gap-3">
    //           <p className="text-base font-bold">{cart.name}</p>
    //           <div className="flex flex-col">
    //             <small className="font-normal">{cart.category}</small>
    //           </div>
    //         </li>
    //       </ul>
    //       <ul className="flex justify-between py-4 px-4 items-center">
    //         <li className="font-semibold">N{cart.outlet_menu?.price}</li>
    //         <li className="p-1 flex items-center gap-4">
    //           <p className="text-primary-60 font-semibold">change</p>
    //           <div className="flex flex-nowrap text-sm rounded-3xl overflow-hidden bg-gray-100">
    //             <button onClick={addItemsToCart} className="px-4 py-3">
    //               <MinusIcon className="w-4 h-4" />
    //             </button>
    //             <div className="px-3 py-3"></div>
    //             <button onClick={addItemsToCart} className=" px-4 py-3">
    //               <PlusIcon className="w-4 h-4" />
    //             </button>
    //           </div>
    //         </li>
    //       </ul>
    //     </section>
    //   ) : (
    //     <section className="flex flex-col bg-white px-4">
    //       <ul className="border-b border-gray-200 flex relative items-center w-full gap-4 p-4">
    //         <li className="w-[30%] bg-gray-600 block shrink-0 grow-0">
    //           <img
    //             src={cart?.image_url}
    //             alt="item image"
    //             className="w-full object-cover"
    //           />
    //         </li>
    //         <li className="flex flex-col grow gap-3">
    //           <p className="text-base font-bold">{cart.name}</p>
    //           <div className="flex flex-col">
    //             <small className="font-semibold">{cart.category}</small>
    //             <small className="text-gray-700">
    //               N{cart.outlet_menu?.price}
    //             </small>
    //           </div>
    //           {cart?.comboItems?.map((item: any, index: number) => (
    //             <div className="flex flex-col" key={index}>
    //               <small className="font-semibold">{item.name}</small>
    //               <small className="text-gray-700">
    //                 Medium, extra toppings
    //               </small>
    //             </div>
    //           ))}
    //         </li>
    //       </ul>
    //       <ul className="flex justify-between py-4 px-4 items-center">
    //         <li className="font-semibold">{cart.outlet_menu?.price}</li>
    //         <li className="p-1 flex items-center gap-4">
    //           <p className="text-primary-60 font-semibold">change</p>
    //           <div className="flex flex-nowrap text-sm rounded-3xl overflow-hidden bg-gray-100">
    //             <button onClick={addItemsToCart} className="px-4 py-3">
    //               <MinusIcon className="w-4 h-4" />
    //             </button>
    //             <div className="px-3 py-3">0 </div>
    //             <button onClick={addItemsToCart} className=" px-4 py-3">
    //               <PlusIcon className="w-4 h-4" />
    //             </button>
    //           </div>
    //         </li>
    //       </ul>
    //     </section>
    //   )}
    // </>
    <div className="border h-[15rem] border-gray-200 rounded-lg overflow-hidden shadow-sm">
      <div className="w-full h-1/2">
        <img
          src={cart.image_url}
          alt={cart.name}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-3">
        <h4 className="text-sm font-semibold truncate">{cart.name}</h4>
        <p className="text-xs text-gray-600 mt-1">{cart.category}</p>

        {cart.isCombo && cart.comboItems && (
          <div className="mt-2">
            <p className="text-xs font-medium">Includes:</p>
            <ul className="text-xs text-gray-600">
              {cart.comboItems.map((item: any, index: number) => (
                <li key={index} className="mt-1">
                  <div className="font-semibold truncate">{item.name}</div>
                  <div className="text-gray-700">Medium, extra toppings</div>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="mt-2 flex justify-between items-center w-full">
          <span className="text-sm font-medium">
            N{cart.outlet_menu?.price}
          </span>
          <button
            className="bg-primary-60 text-white text-xs py-1 px-2 rounded-full hover:bg-primary-70 transition duration-300"
            onClick={addItemsToCart}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
};

export default RelatedCartItem;
