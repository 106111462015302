'use client';

import { addToOrderData, clearCart } from '@/redux/slices/cart.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import AddLocationModal from '@/components/AddLocationModal';
import AppStorage from '@/utils/helpers';
import AuthModal from '@/components/AuthModal';
import { CheckOut } from '@/services/cart.api';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import NoOutletModal from '@/components/NoOutletModal';
import { ValidateCoupon } from '@/services/coupon.api';
import { getErrorMessge } from '@/utils/helpers';
import { reloadMenu } from '@/redux/slices/category.slice';
import { setCoupon } from '@/redux/slices/cart.slice';
import { toast } from 'react-toastify';
import { useRouter } from 'next/navigation';

type CheckOutProps = {
  cartItem: any;
  price: number;
  closeModal: () => void;
  setCartTotalPrice: (price: number) => void;
};

const CheckoutTab = ({
  cartItem,
  price,
  closeModal,
  setCartTotalPrice,
}: CheckOutProps) => {
  const { cart } = cartItem;
  const { orderData, cart: myCart } = useSelector((state: any) => state.cart);
  const [showNoOutletModal, setShowNoOutletModal] = useState(false);
  const { user, isAuthenticated } = useSelector((state: any) => state.auth);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [validateCouponLoading, setValidateCouponLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [code, setCode] = useState('');
  // const [coupon, setCoupon] = useState('');
  const [couponValue, setCouponValue] = useState({
    isValid: false,
    value: 0,
  });
  const [openLogin, setOpenLogin] = useState({
    open: false,
    state: '',
  });
  const dispatch = useDispatch();
  const router = useRouter();
  const { coupon } = useSelector((state: any) => state.cart);
  // const location = useSelector((state: any) => state.userLocation);
  const location = JSON.parse(AppStorage.get('location') as string);

  const checkOut = async () => {
    const orderType = AppStorage.get('orderType');

    if (!location) {
      setOpenModal(true);
      return;
    }

    const menuItems = myCart?.map((cartItem: any) => ({
      menuId: cartItem.id,
      quantity: cartItem.quantity,
    }));

    try {
      setLoading(true);
      if (isAuthenticated) {
        const payload = {
          latitude: location.lat.toString(),
          longitude: location.lng.toString(),
          orderType: orderType,
          couponCode: coupon,
          menuItems,
          userid: user._id,
        };
        const res = await CheckOut(payload);

        dispatch(addToOrderData(res));
        AppStorage.save('orderId', res.serializedOrder._id);
        AppStorage.save('paystackUrl', res.data.authorization_url);
        AppStorage.save('outlet', JSON.stringify(res.serializedOrder.outletId));
      }
      if (!coupon) {
        AppStorage.remove('coupon_type');
        AppStorage.remove('coupon_rate');
        AppStorage.remove('coupon_code');
        AppStorage.remove('couponPrice');
      }

      router.push('/checkout');

      closeModal();
    } catch (error) {
      console.error(error);
      const errorMsg = getErrorMessge(error);
      toast.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  // const applyCoupon = async () => {
  //   const couponCode = AppStorage.get('coupon_code') as string;
  //   if (couponCode === code) {
  //     toast.error('Coupon applied already!');
  //     return;
  //   }
  //   try {
  //     setValidateCouponLoading(true);
  //     const payload = { code: code.trim() };
  //     const res = await ValidateCoupon(payload);

  //     AppStorage.save('coupon_type', res.discount_type);
  //     AppStorage.save('coupon_rate', res.discount_rate);
  //     AppStorage.save('coupon_code', res.code);
  //     dispatch(setCoupon(res.code));
  //     let newPrice;
  //     if (res.discount_type === 'Percentage') {
  //       newPrice = price - (res.discount_rate / 100) * price;
  //     } else {
  //       newPrice = price - res.discount_rate;
  //     }
  //     setCode('');
  //     setCartTotalPrice(newPrice);
  //     AppStorage.save('couponPrice', (price - newPrice).toString());
  //     setCouponValue({ isValid: true, value: price - newPrice });
  //     toast.success('Coupon applied successfully');
  //   } catch (err) {
  //     const errMessage = getErrorMessge(err);
  //     toast.error(errMessage);
  //   } finally {
  //     setValidateCouponLoading(false);
  //   }
  // };

  return (
    <section className="bg-white px-4 py-5 flex flex-col">
      {/* <div className="flex flex-col mb-5 md:mb-0 md:flex-row justify-between">
        <input
          type="text"
          placeholder="Coupon Code..."
          value={code}
          onChange={(e) => setCode(e.target.value)}
          className="py-4 my-0 flex-[.8] w-full md:w-[90%] text border-b border-gray-300 outline-0"
        />
        <button
          disabled={code.length === 0}
          onClick={applyCoupon}
          className={`flex cursor-pointer my-0 md:my-2 flex-[.2] items-center rounded-full h-10 text-center  py-4 px-6 font-medium w-full md:w-[10%] text-white bg-primary-500 `}
        >
          <span className="grow">
            {validateCouponLoading ? 'Applying...' : 'Apply'}
          </span>{' '}
        </button>
      </div> */}

      <section>
        <ul className="flex flex-col gap-3 text-base font-semibold py-6">
          {/* {couponValue.isValid && (
            <li className="flex justify-between">
              <p>Coupon</p>
              <p>₦{couponValue.value.toLocaleString()}</p>
            </li>
          )} */}
          <li className="flex justify-between">
            <p>Total</p>
            <p>₦{price.toLocaleString()}</p>
          </li>
          <li>
            <button
              onClick={checkOut}
              className="flex cursor-pointer items-center rounded-full text-center bg-primary-500 py-4 px-6 font-medium w-full text-white "
            >
              <span className="grow">
                {loading ? 'Loading...' : 'Checkout'}
              </span>
              <ChevronRightIcon className="w-6 h-6" />
            </button>
          </li>
        </ul>
      </section>
      <NoOutletModal
        open={showNoOutletModal}
        closeModal={() => {
          setShowNoOutletModal(false);
        }}
      />

      <AddLocationModal
        openModal={openModal}
        closeModal={() => {
          setOpenModal(false);
          dispatch(reloadMenu(true));
          closeModal();
        }}
      />
      <AuthModal
        open={openLogin}
        closeModal={() => setOpenLogin({ open: false, state: '' })}
        swicthToRegister={() => setOpenLogin({ open: true, state: 'register' })}
        switchToLogin={() => setOpenLogin({ open: true, state: 'login' })}
      />
    </section>
  );
};

export default CheckoutTab;
